import React, { useState } from 'react';
import Layout from '../components/layout'
import Accordion from '../components/accordianModal';

import appliance from "../images/graphic/health/appliance.png";
import electrification from "../images/graphic/health/electrification.png";
import lighting from "../images/graphic/health/lighting.png";
import service from "../images/graphic/health/serviceWaterHeating.png";
import spaceHeating from "../images/graphic/health/spaceHeating.png";

import mainIMG from "../images/graphic/health/main.png";

const GraphicIndustrial = (props) => {
    
    const modalList = [
        {
            title: "Space Heating and Cooling",
            desc: "HVAC can represent a considerable portion of a facility’s energy use. There are many ways to improve HVAC efficiency, from adjusting fan operation during unoccupied hours and implementing energy management systems to installing economizers, utilizing heat recovery, minimizing exhaust and make-up air, and upgrading to the latest equipment.",
            desc2: "Implementing a modern climate control system gives you insights into your building’s energy performance while allowing you to implement system setpoints, establish custom climate zones and occupancy controls, and adjust operating hours — which can all reduce your HVAC energy use and save you money.",
            cost: "4",
            energy: "4",
            pay: "3",
            Akey: spaceHeating,
        },
        {
            title: "Lighting",
            desc: "Switching to LEDs presents one of the easiest energy efficiency programs for facilities to implement. Lighting upgrades have a short payback period, typically realizing ROI between one and three years. Additionally, modern high-efficiency LED light sources provide more illumination at a lower operating cost — meaning you can improve your work environment while also improving energy performance.",
            desc2: "Beyond upgrading to LED lights, occupancy sensors offer additional energy-saving opportunities.",
            cost: "1",
            energy: "1",
            pay: "2",
            Akey: lighting,
        },
        {
            title: "Appliance or Plug Load",
            desc: "Occupancy sensor-based controls can reduce lighting and refrigeration consumption of vending machines and beverage merchandise coolers when traffic drops below a certain threshold.",
            desc2: "",
            cost: "5",
            energy: "5",
            pay: "4",
            Akey: appliance,
        },
        {
            title: "Service Water Heating",
            desc: "Installing insulation to bare liquid and solution storage or transfer takes greatly reduces heat loss to ambient air, providing significant energy savings for a low cost.",
            desc2: "",
            cost: "3",
            energy: "3",
            pay: "1",
            Akey: service,
        },
        {
            title: "Electrification",
            desc: "Shifting from fossil fuels to electricity for heating and cooking allows for these processes to be powered by zero-carbon sources of electricity. This includes solutions like split heat pumps for space heating, which relies on an all-electric air source heat pump paired with supplemental electric resistance heating.",
            desc2: "",
            cost: "2",
            energy: "2",
            pay: "5",
            Akey: electrification,
        }
    ];
    let param = props.location.search;
    let filter = param.slice(8);
    const [filterName, setfilterName] = useState('this string');
    let buttonSelctor = (buttonID) => {
        let btns = document.querySelectorAll('.filterBtn'); 
        btns.forEach(element => element.classList.remove('choosenFilter'));
        if(filterCost === true || filterEnergy === true || filterCarbon === true){
            document.querySelector("#"+buttonID).classList.add('choosenFilter');
        }
    }

    const [dataObject, setdataObject] = useState(modalList);
    
    const costSort = () => {
        buttonSelctor('cost');
        setfilterName("Cost Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.cost) - Number(b.cost));
          return dataToSort;
        });
        
    };
    const energySort = () => {
        buttonSelctor('energy');
        setfilterName("Energy Usage Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.energy) - Number(b.energy));
          return dataToSort;
        });
    };
    const paySort = () => {
        buttonSelctor('carbon');
        setfilterName("Carbon Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.pay) - Number(b.pay));
          return dataToSort;
        });
    };
    
    const [Image, setImage] = useState(mainIMG);
    const setImageVar = (e) => {
        if(Image === e){
            setImage(mainIMG);
        }
        else{
            setImage(e);
        }
    }
    
    const [filterCost, setfilterCost] = useState(false);
    if(filterCost !== true && filter === 'cost'){
        costSort();
        setfilterCost(true);
    }

    const [filterEnergy, setfilterEnergy] = useState(false);
    if(filterEnergy !== true && filter === 'energy'){
        energySort();
        setfilterEnergy(true);
    }

    const [filterCarbon, setfilterCarbon] = useState(false);
    if(filterCarbon !== true && filter === 'carbon'){
        paySort();
        setfilterCarbon(true);
    }

    return (
        <Layout nextBottomSlideBtn="Implementation" nextBottomSlideBtnLink="/Implementation" id="works">
            <ul className="priceNav">
                <strong>Sort by:</strong>
                <li onClick={costSort}><button className="filterBtn" id="cost" >Cost Reduction</button></li>
                <li onClick={paySort}><button className="filterBtn" id="carbon">Carbon Reduction</button></li>
                <li onClick={energySort}><button className="filterBtn" id="energy">Energy Usage Reduction</button></li>
            </ul>
            <div className="fullWidth">
                <div className="buttons">
                    <h1 id="filterName"><strong>{filterName}</strong></h1>
                    <br></br>
                    <div className="options">
                        {dataObject.map((data) => (
                            <Accordion 
                                title={data.title} 
                                desc={data.desc}
                                desc2={data.desc2}
                                annualCost={data.annualCost}
                                payback={data.paybac}
                                annualCarbon={data.annualCarbon}
                                upfront={data.upfront}
                                cost={data.cost} 
                                energy={data.energy} 
                                pay={data.pay}
                                Akey={data.Akey}
                                onClick = { () => setImageVar(data.Akey)}
                            />
                        ))}
                    </div>
                </div>
                <div className="graphic">
                    <img
                        alt="Health"
                        src= {Image}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default GraphicIndustrial